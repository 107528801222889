import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 打印企业证书证明
// export const printQyDjzs = (params) =>{
//     return request({
//       url: REST_SERVICE.print.printQyDjzs,
//       method: 'post',
//       params
//     });
//   }

// 获取业务号
export const saveELicense = (data) =>
  request({
    url: REST_SERVICE.dzzz.saveELicense + '?areaCode=' + data.areaCode + '&userId=' + data.userId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    data,
  });

//  电子证照查询结果
export const getQueryResult = (data) =>
  request({
    url:
      REST_SERVICE.dzzz.getQuertResult +
      '?nCurrent=' +
      data.nCurrent +
      '&nSize=' +
      data.nSize +
      '&userId=' +
      data.userId +
      '&ywh=' +
      data.ywh,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

// 电子证照历史记录查询结果
export const getQueryHistoryResult = (data) =>
  request({
    url:
      REST_SERVICE.dzzz.getQuertResult +
      '?nCurrent=' +
      data.nCurrent +
      '&nSize=' +
      data.nSize +
      '&userId=' +
      data.userId +
      '&ywh=' +
      data.ywh +
      '&startTime=' +
      data.startTime +
      '&endTime=' +
      data.endTime,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

// 保存上传图片的信息
export const saveUploadFileInfo = (data) =>
  request({
    url: REST_SERVICE.dzzz.saveUploadFileInfo,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
// 电子证照查询
export const queryCeritificateList = (data, params) =>
  request({
    url: REST_SERVICE.dzzz.queryCeritificateList + '?userId=' + data.userId + '&areaCode=' + data.areaCode,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });

// 2.4 电子证照查验
export const verifyCeritificate = (data) =>
  request({
    url: REST_SERVICE.dzzz.verifyCeritificate,
    method: 'post',
    data: data,
  });

// 2.4 电子证照下载
export const downloadFile = (data) =>
  request({
    url: REST_SERVICE.dzzz.downloadFile + '?userId=' + data.userId + '&areaCode=' + data.areaCode,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  });

// base64转码
export const translate = (data) =>
  request({
    url: REST_SERVICE.dzzz.translate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    data: data,
  });

//电子证照核验m
export const getVerifyCeritificateResult = (data) =>
  request({
    url: REST_SERVICE.dzzz.getVerifyCeritificateResult + '?userId=' + data.userId + '&areaCode=' + data.areaCode,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  });

// 2.3通过共享交换下载电子证照文件
export const downloadCeritificate = (data) =>
  request({
    url: REST_SERVICE.dzzz.downloadCeritificate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
