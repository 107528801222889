<template>
  <div class="content">
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXCX:DZZZ:SFZJ')">
      <div>
        <el-tabs v-model="activeTabName">
          <el-tab-pane label="查询电子证照" name="certificateQuery">
            <div class="form-contnet">
              <el-form label-width="120px" :model="certificateQueryForm" :rules="zzcxRules" ref="certificateQueryForm">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="权利人名称" prop="obligeeName">
                      <el-input v-model="certificateQueryForm.obligeeName" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="权利人证件号" prop="obligeeIDNumber">
                      <el-input v-model="certificateQueryForm.obligeeIDNumber" disabled></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="text-right">
                  <el-button type="info" @click="resetForm('certificateQueryForm')">清除</el-button>
                  <el-button type="primary" @click="getBussinessId" :loading="loadingXc">查询</el-button>
                </div>
              </el-form>
            </div>
            <div id="queryResult">
              <div class="queryResult">查询结果</div>
              <el-table
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                :data="certificateQueryResult"
                style="width: 100%"
              >
                <el-table-column prop="bussinessId" label="业务号"> </el-table-column>
                <el-table-column prop="obligeeName" label="权利人名称"></el-table-column>
                <el-table-column prop="obligeeIDNumber" label="权利人证件号"> </el-table-column>
                <el-table-column prop="propertyCertificateNumber" label="不动产权证号"> </el-table-column>
                <el-table-column prop="CertificatName" label="证照名称"> </el-table-column>
                <el-table-column prop="promulgateTime" label="电子证照颁发时间"></el-table-column>
                <el-table-column prop="registry" label="登记机构"></el-table-column>
                <el-table-column prop="queryResult" label="登记原因"> </el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button @click="downLoad(scope.row, scope)" size="small" :loading="scope.row.downShow"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="电子证照核验" name="certificateVerification" v-if="false">
            <div class="form-contnet">
              <el-form
                label-width="120px"
                :model="certificateVerificationForm"
                :rules="certificateVerificationRules"
                ref="certificateVerificationForm"
              >
                <el-upload
                  ref="upload"
                  action="http://xjbdc.greatmap.com.cn:9000/fms-server/file/uploadFile"
                  :on-success="saveUploadFile"
                  class="uploadButtom"
                  :limit="1"
                  :on-exceed="handleExceed"
                  :on-remove="handleRemove"
                  list-type="picture-card"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="用户名" prop="userName">
                      <el-input v-model="certificateVerificationForm.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="不动产权证号" prop="certificateNumber">
                      <el-input v-model="certificateVerificationForm.certificateNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="登记机构" prop="registry">
                      <el-input v-model="certificateVerificationForm.registry"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="业务号" prop="ywh">
                      <el-input v-model="certificateVerificationForm.ywh"></el-input>
                    </el-form-item>
                    <el-form-item label="证件种类" prop="certificateType">
                      <el-select v-model="certificateVerificationForm.value" class="width100">
                        <el-option
                          v-for="item in certificateVerificationForm.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="行政区名称" prop="administrativeName">
                      <el-cascader
                        :options="selectAreaRules"
                        size="medium"
                        :props="{ checkStrictly: true, emitPath: false }"
                        :show-all-levels="false"
                        v-model="certificateVerificationForm.zlData.zlArea"
                        clearable
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- </el-form> -->
                <div class="text-right">
                  <el-button type="info" @click="resetForm('certificateVerificationForm')">清除</el-button>
                  <el-button type="primary" @click="certificateVerificationSubmit()" :loading="loadingHy"
                    >核验</el-button
                  >
                </div>
              </el-form>
            </div>
            <div class="queryResult">核验结果</div>
            <div id="certificateVerificationResult">
              <table class="tableData" cellpadding="0" cellspacing="0" v-if="queryFlag">
                <tr>
                  <td class="bgcolor">权利人</td>
                  <td v-if="certificateVerificationResult.yhm">
                    {{ certificateVerificationResult.yhm }}
                  </td>
                  <td v-else>/</td>
                  <td class="bgcolor">不动产权证号</td>
                  <td v-if="certificateVerificationResult.zh">
                    {{ certificateVerificationResult.zh }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">查询所在行政区</td>
                  <td v-if="certificateVerificationResult.areaCode">
                    {{ certificateVerificationResult.areaCode }}
                  </td>
                  <td v-else>/</td>
                  <td class="bgcolor">查询编号</td>
                  <td v-if="certificateVerificationResult.cxbh">
                    {{ certificateVerificationResult.cxbh }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">登记机构</td>
                  <td v-if="certificateVerificationResult.djjg">
                    {{ certificateVerificationResult.djjg }}
                  </td>
                  <td v-else>/</td>
                  <td class="bgcolor">IP</td>
                  <td v-if="certificateVerificationResult.ip">
                    {{ certificateVerificationResult.ip }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">创建时间</td>
                  <td v-if="certificateVerificationResult.ctime">
                    {{ certificateVerificationResult.ctime }}
                  </td>
                  <td v-else>/</td>
                  <td class="bgcolor">核验时间</td>
                  <td v-if="certificateVerificationResult.utime">
                    {{ certificateVerificationResult.utime }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">证件坐落</td>
                  <td v-if="certificateVerificationResult.zjzl">
                    {{ certificateVerificationResult.zjzl }}
                  </td>
                  <td v-else>/</td>
                  <td class="bgcolor">证件类型</td>
                  <td v-if="certificateVerificationResult.zzlx">
                    {{ certificateVerificationResult.zzlx }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">核验结果</td>
                  <td v-if="certificateVerificationResult.result" colspan="3">
                    {{ certificateVerificationResult.result }}
                  </td>
                  <td v-else>/</td>
                </tr>
                <tr>
                  <td class="bgcolor">请求服务结果</td>
                  <td v-if="certificateVerificationResult.rtnMessage" colspan="3">
                    {{ certificateVerificationResult.rtnMessage }}
                  </td>
                  <td v-else>/</td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { queryCeritificateList, downloadFile, getVerifyCeritificateResult } from '@/pages/iebdc/api/electronic-license';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
import hasPermission from '@iebdc/mixin/hasPermission';
import { localDataUser } from '@iebdc/utils/local-data';
import { mapGetters } from 'vuex';
import hasConstruction from '@iebdc/mixin/hasConstruction';
import { getCookie } from '../../utils/cookies';
import utils from '../../utils/utils';

export default {
  name: 'certificateVerification',
  mixins: [hasPermission, hasConstruction],
  data() {
    return {
      showDownLoad: false, // 下载加载中
      certificate: true, // 是否显示查询和历史查询
      loadingXc: false, // 查询
      loadingLsjl: false, // 历史记录
      loadingHy: false, // 核验
      arr1: [],
      queryFlag: false, // 核验
      loading: false, // 下载
      userInfo: localDataUser.get(), // 用户信息
      activeTabName: 'certificateQuery', //控制页签显示和切换
      certificateQueryForm: {
        //证照查询表单
        obligeeName: '', // 权利人名称
        ip: '', // IP地址
        registry: '', // 登记机构
        obligeeIDNumber: '', // 权利人证件号
        userName: '', // 用户名
        certificatNumber: '', // 证号：不动产权证号/不动产登记证明号
        zlData: {
          zlProvince: getCookie('selectedShengCode'),
          zlCity: getCookie('selectedShiCode'),
          zlArea: getCookie('selectedAreaCode'),
        },
        bussinessId: '',
      },
      selectAreaRules: this.handleSelectArea(JSON.parse(localStorage.getItem('finalAreaDataLocal'))),
      certificateVerificationForm: {
        //证照核验表单
        certificateNumber: '',
        userName: '',
        bussinessId: '',
        certificateType: '不动产权证书',
        registry: '',
        options: [
          {
            value: '不动产权证书',
            label: '不动产权证书',
          },
          {
            value: '证明',
            label: '证明',
          },
        ],
        value: '不动产权证书',
        zlData: {
          zlProvince: getCookie('selectedShengCode'),
          zlCity: getCookie('selectedShiCode'),
          zlArea: getCookie('selectedAreaCode'),
        },
        ywh: '',
        zjh: '',
      },
      zzcxRules: {
        //证照查询 表单验证配置项
        obligeeIDNumber: [{ required: true, message: '请输入权利人证件号', trigger: 'blur' }],
        obligeeName: [{ required: true, message: '请输入权利人名称', trigger: 'blur' }],
      },
      certificateVerificationRules: {
        //证照核验 表单验证配置项
        userName: { required: true, message: '此项为必填项', trigger: 'change' },
        registry: { required: true, message: '此项为必填项', trigger: 'change' },
        certificateNumber: { required: true, message: '此项为必填项', trigger: 'change' },
        certificateType: { required: true, message: '此项为必填项', trigger: 'change' },
        'zlData.zlArea': [{ required: true, message: '必填', trigger: 'change' }],
        ywh: { required: true, message: '此项为必填项', trigger: 'change' },
      },
      certificateQueryResult: [], //查询电子证照 的结果
      queryHistoryResult: [], //电子证照查询 历史记录的结果
      certificateVerificationResult: [], //证照核验 的结果
      dialogImageUrl: '',
      dialogVisible: false, //上传图片相关参数
      upLoadFileList: [], //上传图片文件列表
      certificateVerificationResultSize: 10, //电子证照核验 结果每页显示条数
      certificateVerificationResultTotal: 0, //电子证照核验 结果总条数
      isPicture: false, //是否上传了图片
      fileId: '', //上传的文件id
      djjgDictionaries: [],
    };
  },
  computed: {
    ...mapGetters(['selectedOrganizationsCode', 'selectedOrganizationsTitle', 'finalAreaData']),
  },
  methods: {
    // 级联选择器区域数据
    handleSelectArea(arr) {
      arr.map((item) => {
        item.value = item.code;
        item.label = item.title;
        if (Object.prototype.toString.call(item.childrens) === '[object Array]') {
          item.children = this.handleSelectArea(item.childrens);
        }
      });
      return arr;
    },
    // 根据返回的code判断区域
    setSelectArea(arr) {
      arr.forEach((item) => {
        this.arr1.push({
          code: item.code,
          title: item.title,
        });
        if (Object.prototype.toString.call(item.childrens) === '[object Array]') {
          this.setSelectArea(item.childrens);
        }
      });
      return this.arr1;
    },
    // 判断返回的数据类型
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == 'object') {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    //查询电子证照
    getBussinessId() {
      this.$refs.certificateQueryForm.validate((valid) => {
        if (valid) {
          //百度统计
          // if (window.CONFIG.isOpenBDTJ === true) _hmt.push(['_trackEvent', '我要查询', '我的证照查询', '证照查询']);
          this.loadingXc = true;
          this.certificateQueryResult = [];
          const data = {
            areaCode: this.certificateQueryForm.zlData.zlArea,
            userId: this.userInfo.userId,
          };
          const params = {
            areaCode: this.certificateQueryForm.zlData.zlArea,
            queryListElicenseParam: {
              qlrmc: this.certificateQueryForm.obligeeName,
              ip: this.certificateQueryForm.ip,
              djjg: this.certificateQueryForm.zlData.zlArea, //this.certificateQueryForm.registry,
              qlrzjh: this.certificateQueryForm.obligeeIDNumber,
              yhm: this.certificateQueryForm.userName,
              zh: this.certificateQueryForm.certificatNumber,
            },
            userId: this.userInfo.userId,
          };
          queryCeritificateList(data, params)
            .then((res) => {
              if (res.success) {
                this.loadingXc = false;
                if (res.data.result && this.isJsonString(res.data.result)) {
                  if (JSON.parse(res.data.result).length !== 0) {
                    var res1 = JSON.parse(res.data.result);
                    res1.forEach((item) => {
                      this.certificateQueryResult.push({
                        areaCode: res.data.areaCode,
                        ip: res.data.ip,
                        bussinessId: res.data.cxbh,
                        propertyCertificateNumber: item.bdcqzh,
                        registry: item.qqbfdjjg,
                        CertificatName: item.zzmc,
                        obligeeName: item.qlrmc,
                        obligeeIDNumber: item.qlrzjh,
                        promulgateTime: utils.dateFtt(item.bfsj, 'yyyy-MM-dd hh:mm:ss'),
                        queryResult: item.bfyy,
                        status: item.zt === '1' ? '有效' : '无效',
                        rtnMessage: res.data.rtnMessage,
                        downShow: false,
                      });
                    });
                  } else {
                    this.certificateQueryResult = [];
                    this.$message({
                      type: 'warning',
                      message: '未查询到您的不动产权信息',
                    });
                  }
                } else {
                  this.certificateQueryResult = [];
                  this.$message({
                    type: 'warning',
                    message: '未查询到您的不动产权信息',
                  });
                }
                console.log(this.certificateQueryResult, '电子证照查询');
              } else if (!res.success) {
                this.loadingXc = false;
              }
            })
            .catch(() => {
              this.loadingXc = false;
            });
        }
      });
    },

    //点击核验按钮
    certificateVerificationSubmit() {
      const that = this;
      this.$refs.certificateVerificationForm.validate((valid) => {
        if (valid) {
          //确保已上传图片
          if (this.isPicture) {
            const params2 = {
              uploadfiles: [
                {
                  clfl: '电子证照证明查询',
                  cllb: '',
                  clmc: '电子证照证明查询1.ofd',
                  clsm: '电子证照证明查询',
                  fileId: that.upLoadFileList[0].id,
                  fileSize: 0,
                  fileUrl: that.upLoadFileList[0].filePathUrl,
                  show: true,
                  sxh: 0,
                  ywh: this.certificateVerificationForm.ywh,
                },
              ],
            };

            //保存电子证照核验用文件
            applicantUploadService
              .saveFileUploadInfo(params2)
              .then((res) => {
                if (res.success && res.data) {
                  this.loadingHy = true;
                  this.fileId = that.upLoadFileList[0].id;
                  const params = {
                    areaCode: this.certificateVerificationForm.zlData.zlArea,
                    userId: this.userInfo.userId,
                    verifyElicenseParam: {
                      djjg: this.certificateVerificationForm.registry,
                      file: this.fileId,
                      ip: '192.168.0.0',
                      yhm: this.certificateVerificationForm.userName,
                      zh: this.certificateVerificationForm.certificateNumber,
                      zjzl: this.certificateVerificationForm.value,
                    },
                    // ywh: this.certificateVerificationForm.bussinessId,
                  };
                  getVerifyCeritificateResult(params)
                    .then((res) => {
                      if (res.data && res.success) {
                        this.loadingHy = false;
                        this.certificateVerificationResult = res.data;
                        this.certificateVerificationResult.utime = utils.dateFtt(
                          this.certificateVerificationResult.utime,
                          'yyyy-MM-dd hh:mm:ss'
                        );
                        this.certificateVerificationResult.ctime = utils.dateFtt(
                          this.certificateVerificationResult.ctime,
                          'yyyy-MM-dd hh:mm:ss'
                        );
                        this.certificateVerificationResult.result = JSON.parse(res.data.result)[0].result;
                        var arr2 = this.setSelectArea(JSON.parse(localStorage.getItem('finalAreaDataLocal')));
                        console.log(arr2);
                        arr2.forEach((item) => {
                          if (item.code === this.certificateVerificationResult.areaCode) {
                            this.certificateVerificationResult.areaCode = item.title;
                          }
                        });
                        this.queryFlag = true;
                      } else {
                        this.loadingHy = false;
                        this.$message({
                          type: 'info',
                          message: '暂无数据！',
                        });
                        return;
                      }
                    })
                    .catch(() => {
                      this.loadingHy = false;
                    });
                } else {
                  this.loadingHy = false;
                }
              })
              .catch(() => {
                this.loadingHy = false;
              });
          } else {
            this.$message({
              type: 'warning',
              message: '请完善信息再查询',
            });
          }
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loadingXc = false;
      this.loadingLsjl = false;
      this.loadingHy = false;
    },
    // 下载文件 ？
    downLoad(row) {
      if (row.rtnMessage !== '查询成功！') {
        this.$message({
          type: 'warning',
          message: '未查询到您的电子证照,不能下载',
        });
        return;
      } else {
        row.downShow = true;
        const params = {
          areaCode: row.areaCode,
          userId: this.userInfo.userId,
          downloadElicenseParam: {
            djjg: row.areaCode || '',
            ip: row.ip,
            yhm: row.bussinessId,
            zh: row.propertyCertificateNumber || '',
          },
        };
        downloadFile(params)
          .then((res) => {
            if (res.data && res.success) {
              row.downShow = false;
              if (res.data.rtnFileLink) {
                window.open(res.data.rtnFileLink);
              } else {
                this.$message({
                  type: 'warning',
                  message: '未查询到您的电子证照,不能下载',
                });
              }
            } else {
              this.$message.warning('下载失败!');
            }
          })
          .catch(() => {
            row.downShow = false;
          });
      }
    },
    handleExceed() {
      this.$message({
        showClose: true,
        message: '仅限上传一张图片',
        type: 'warning',
      });
    },
    handleRemove() {
      this.$message({
        showClose: true,
        type: 'warning',
        message: '请上传图片',
      });
      this.isPicture = false;
    },
    saveUploadFile(response) {
      console.log(response);
      // 图片上传成功时保存信息
      this.isPicture = true;
      this.fileId = response.data.id;
      this.$message({
        showClose: true,
        message: '图片上传成功',
        type: 'success',
      });
      this.upLoadFileList = [];
      this.upLoadFileList.push(response.data);
    },
  },
  created() {
    this.$bus.$on('selectedZlData', (msg) => {
      this.certificateQueryForm.zlData = msg;
    });
    this.certificateQueryForm.obligeeName = this.userInfo.name;
    this.certificateQueryForm.obligeeIDNumber =
      this.userInfo.userCategory === 'NORMAL'
        ? this.userInfo.zjh
        : this.userInfo.qyzjh;
  },
  beforeDestroy() {
    this.$bus.$off('selectedZlData');
  },
  mounted() {
    // 获取区域code与名称字典
    if (this.finalAreaData[0]) {
      this.finalAreaData[0].childrens.forEach((item1) => {
        item1.childrens.forEach((item2) => {
          item2.bsdtData.forEach((item3) => {
            this.djjgDictionaries.push({ registry: item3.registry, jgmc: item3.jgmc });
          });
        });
      });
    }
    console.log('this.certificateQueryForm----', this.certificateQueryForm);
  },
};
</script>

<style lang="scss" scoped>
@mixin pub-tip {
  border-bottom: 1px solid #dedede;
  font-weight: bold;
}

.activeClass {
  background-color: rgba(255, 91, 36, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}

.bottom {
  margin-top: 40px;

  .bottom-tip {
    @include pub-tip;

    span {
      $color: #ff5b24;
      display: block;
      height: 40px;
      width: 65px;
      line-height: 40px;
      text-align: left;
      color: #000;
      border-bottom: 2px solid $color;
    }
  }

  .bottom-center {
    margin-top: 20px;

    .inline-block {
      display: inline-block;
      width: 86px;
      height: 40px;
      margin-right: 30px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 91, 36, 1);
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 91, 36, 1);
      cursor: pointer;
    }
  }

  .bottom-container {
    .bottom-container-title {
      margin: 30px 0px 20px 0;
      // text-align: center;
      margin: 20px auto;
      font-size: 16px;
      color: rgba(36, 123, 255, 1);
      width: 64px;
      height: 24px;
      line-height: 24px;
      cursor: pointer;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-carousel--horizontal {
    width: 693px;
    margin: 0 auto;
  }
}

.form-contnet {
  margin-bottom: 10px;
  padding: 20px 30px;
  border: 1px solid #dedede;
}
/deep/.e-table {
  width: 100%;
  .cell {
    white-space: pre-line !important;
  }
}

.queryResult {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  font-weight: bolder;
}
#queryHistory {
  margin-top: 20px;
}
.uploadButtom {
  display: block;
  text-align: center;
  margin: 0px 0 20px 0;
}
.uploadButtom .el-form-item__error {
  width: 100%;
}
.width100 {
  width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader--medium {
  width: 250px;
}
table {
  border-collapse: collapse;
  margin: 15px 0;
  width: 100%;
  border: solid #e0e0e0;
  border-width: 1px 0px 0px 1px;
}
table tr td {
  // height: 46px;
  line-height: 18px;
  border: solid #e0e0e0;
  border-width: 0px 1px 1px 0px;
  padding: 10px 10px;
}

table tr td:nth-child(2n-1) {
  text-align: center;
  background: rgb(242, 246, 252);
  width: 14%;
  word-break: break-all;
}
table tr td:nth-child(2n) {
  width: 36%;
  word-break: break-all;
}

.bgcolor {
  background: rgb(242, 246, 252);
  text-align: center;
  width: 200px;
}
</style>
